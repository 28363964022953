<template>
  <div>
    <b-card-text>{{card_title}}</b-card-text>

    <simple-filter :filters="filters" @search="search"></simple-filter>
    <div class="mb-4 mt-4 text-center" v-if="transaction_list.length">
      Dokonanych zakupów: {{ transaction_list.length }}
    </div>
    <b-table  id="transaction-list" striped hover
              :per-page="perPage"
              :current-page="currentPage"
              :items="transaction_list"  small></b-table>
    <div v-if="transaction_list.length === 0">
      Brak wyników :(
    </div>
    <b-pagination
      align="center"
      pills
      v-model="currentPage"
      :total-rows="transaction_list.length"
      :per-page="perPage"
      :limit="10"
      aria-controls="transaction-list"
    ></b-pagination>

  </div>
</template>

<script>
import SimpleFilter from '@/components/SimpleFilter.vue';
import UserService from '@/_services/user.service';

const userService = new UserService();
export default {
  name: 'UserData',
  components: { SimpleFilter },
  props: {
    user: Object,
    card_title: String,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filters: {
        year: { value: '', visible: true },
        month: { value: '', visible: true },
      },
      transaction_list: [],
      loading: false,
    };
  },
  methods: {
    search() {
      userService.getTransactions(this.filters)
        .then((result) => {
          this.$store.state.loading = false;
          this.transaction_list = [];
          Object.values(result).forEach((value) => {
            const element = [];
            element.ID = value.transaction_id;
            element['Miejscowość'] = `${value.city}`;
            element['Kwota w programie'] = value.value;
            element.Punkty = value.points + value.points_promotions;
            element.Data = value.date;
            this.transaction_list.push(element);
          });
        });
    },
  },
  created() {
    this.search();
  },
};
</script>
