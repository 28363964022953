<template>
  <b-container fluid>
    <b-container>
        <h1>Konto online</h1><br/>
      <h2>Masz do wykorzystania: <strong>{{points}} pkt.</strong></h2><br/>
      Posiadane karty (numery):
      <ul>
        <li v-for="(item, index) in cardsList" :key="index">{{item.nr}}</li>
      </ul>
      <br/>
      <div v-if="cardsList.length === 0">Nie posiadasz żadnych aktywnych kart :(</div>
      <div>
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Moje dane">
                <user-data :user="user" :card_title = "`Edycja danych`" />
            </b-tab>
            <b-tab title="Moje zakupy">
              <transaction-list :user="user" :card_title = "`Historia zakupów`" />
            </b-tab>
            <b-tab title="Punkty uznaniowe">
              <extra-points :card_title="`Historia punktów ekstra`" :user="user" />
            </b-tab>

            <b-tab title="Moje nagrody">
              <my-awards :card_title="`Historia nagród`" :user="user" />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

    </b-container>
  </b-container>
</template>

<script>
// komponenty
import UserData from '@/components/User/UserData.vue';
import TransactionList from '@/components/User/TransactionList.vue';
import ExtraPoints from '@/components/User/ExtraPoints.vue';
import MyAwards from '@/components/User/MyAwards.vue';

// services
import UserService from '@/_services/user.service';
import CardService from '@/_services/card.service';

const cardService = new CardService();
const userService = new UserService();

export default {
  name: 'user',
  components: {
    UserData, TransactionList, ExtraPoints, MyAwards,
  },
  data() {
    return {
      cardsList: [],
      points: 0,
      loading: false,
      user: {
        token: null,
        birth_date: null,
        cell_phone: null,
        sex: null,
        f_name: null,
        l_name: null,
        street: null,
        house_nr: null,
        local_nr: null,
        city: null,
        post_code: null,
        shop_id: null,
        email: null,
        marketing: 0,
        marketing_ext: 0,
      },
    };
  },
  async beforeCreate() {
    this.$store.state.loading = true;

    await userService.getUserData().then((response) => {
      Object.keys(response).forEach((key) => {
        if (typeof this.user[key] !== 'undefined') {
          this.user[key] = response[key];
        }
        this.$store.state.loading = false;
      });
      if (response.points === null) {
        this.points = 0;
      } else {
        this.points = response.points;
      }
    });
  },
  created() {
    this.getAccountInformation();
  },
  methods: {
    getAccountInformation() {
      return cardService.getCardList().then((response) => {
        this.cardsList = response;
      });
    },
  },
};
</script>
