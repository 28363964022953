<template>
  <div>
    <b-card-text>{{card_title}}</b-card-text>
    <simple-filter :filters="filters" @search="search"></simple-filter>
    <b-table striped hover :items="extra_points"></b-table>
    <div v-if="extra_points.length === 0">
      Brak wyników :(
    </div>
  </div>
</template>

<script>
import UserService from '@/_services/user.service';
import SimpleFilter from '@/components/SimpleFilter.vue';

const userService = new UserService();
export default {
  name: 'ExtraPoints',
  components: { SimpleFilter },
  props: {
    user: Object,
    card_title: String,
  },
  data() {
    return {
      filters: {
        year: { value: '', visible: true },
        month: { value: '', visible: false },
      },
      extra_points: [],
      loading: false,
    };
  },
  methods: {
    search() {
      userService.getPointsExtra(this.filters)
        .then((result) => {
          this.$store.state.loading = false;
          this.extra_points = [];
          Object.values(result).forEach((value) => {
            const element = [];
            element.ID = value.id;
            // jeśli brak opisu
            if (typeof value.desc[0] !== 'undefined') {
              element.Opis = value.desc[0].desc;
            } else {
              element.Opis = 'Brak opisu';
            }
            element['Ilość punktów'] = value.value;
            element['Data przyznania'] = value.date;
            this.extra_points.push(element);
          });
        });
    },
  },
  created() {
    this.search();
  },
};
</script>
