<template>
  <div>
    <b-card-text>{{card_title}}</b-card-text>
    <simple-filter :filters="filters" @search="search"></simple-filter>
    <b-table striped hover :items="award_list"></b-table>
    <div v-if="award_list.length === 0">
      Brak wyników :(
    </div>
  </div>
</template>

<script>

import UserService from '@/_services/user.service';
import SimpleFilter from '@/components/SimpleFilter.vue';

const userService = new UserService();

export default {
  name: 'MyAwards',
  components: { SimpleFilter },
  props: {
    user: Object,
    card_title: String,
  },
  data() {
    return {
      filters: {
        year: { value: '', visible: true },
        month: { value: '', visible: false },
      },
      award_list: [],
      loading: false,
    };
  },
  methods: {
    search() {
      userService.getOrders(this.filters)
        .then((result) => {
          this.$store.state.loading = false;
          this.award_list = [];
          Object.values(result).forEach((value) => {
            const element = [];
            console.log(value);
            element['Nazwa nagrody'] = value.title;
            element.Status = value.status;
            element['Koszt w punktach'] = value.value;
            element.Data = value.date;
            element['Data realizacji'] = value.realization_date;
            this.award_list.push(element);
          });
        });
    },
  },
  created() {
    this.search();
  },
};
</script>
